import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import { Observable, firstValueFrom } from 'rxjs';
import { ShirosatoService } from 'src/app/services/shirosato-2024.service';
import {
  IPackagegoods,
  OnlineSetting,
} from 'src/lib/repository/packagegoods/packagegoods.model';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { INDEX_TO_DOW, Utils } from 'src/lib/utils';
import { DialogService } from '../../services/dialog.service';
import { Package2024Service } from '../../services/package2024.service';
import { UiService } from '../../services/ui.service';
import { CalendarMap } from '../booking-calendar/booking-calendar.component';

/**
 * 일본 휴일
 *
 * FIXME: 패키지의 휴일 설정 보도록 변경 후 제거
 */
const jpHolidayList: string[] = [
  '2024-01-01',
  '2024-01-08',
  '2024-02-11',
  '2024-02-12',
  '2024-02-23',
  '2024-03-20',
  '2024-04-29',
  '2024-05-03',
  '2024-05-04',
  '2024-05-05',
  '2024-05-06',
  '2024-07-15',
  '2024-08-11',
  '2024-08-12',
  '2024-09-16',
  '2024-09-22',
  '2024-09-23',
  '2024-10-14',
  '2024-11-03',
  '2024-11-04',
  '2024-11-23',
  '2025-01-01', // 元日
  '2025-01-13', // 成人の日
  '2025-02-11', // 建国記念の日
  '2025-02-23', // 天皇誕生日
  '2025-02-24', // 振替休日
  '2025-03-20', // 春分の日
  '2025-04-29', // 昭和の日
  '2025-05-03', // 憲法記念日
  '2025-05-04', // みどりの日
  '2025-05-05', // こどもの日
  '2025-05-06', // 振替休日
  '2025-07-21', // 海の日
  '2025-08-11', // 山の日
  '2025-09-15', // 敬老の日
  '2025-09-23', // 秋分の日
  '2025-10-13', // スポーツの日
  '2025-11-03', // 文化の日
  '2025-11-23', // 勤労感謝の日
  '2025-11-24', // 振替休日
];

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Input() dataBeforeChange: any;

  @Output() monthChange = new EventEmitter<Date>();

  @Output() startDateChange = new EventEmitter<string>();

  @Output() returnDateChange = new EventEmitter<string>();

  @Output() dateSelected = new EventEmitter<[string, string]>();

  @Output() dateResetted = new EventEmitter<void>();

  @Output() lastAvailDateSelect = new EventEmitter<void>();

  @Input() yearList?: number[];

  @Input() canShow = true;

  @Input() isLoading = false;

  @Input() data?: CalendarMap;

  @Input() tourNumber?: number;

  @Input() tourTerm?: string;

  @Input() packagegoods?: IPackagegoods;

  @Input() startPoint?: string;

  @Input() destinationPoint?: string;

  #disabled?: boolean;

  @Input() set disabled(value: BooleanInput) {
    this.#disabled = coerceBooleanProperty(value);
  }

  get disabled(): BooleanInput {
    return this.#disabled;
  }

  today?: string;

  monthList: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  calendar?: any[][];

  #year?: number;

  /**
   * 시분초 제거한 오늘 dayjs
   */
  todayDayjs?: Dayjs;

  @Input()
  set year(value: number) {
    if (this.isValidNumber(value)) {
      if (value !== this.#year) {
        // 값 다르면 변경 및 데이터 재생성
        this.#year = value;

        this.makeCalendar();
      }
    }
  }

  get year(): number {
    return this.#year!;
  }

  #month?: number;

  @Input()
  set month(value: number) {
    if (this.isValidNumber(value)) {
      if (value !== this.#month) {
        // 값 다르면 변경 및 데이터 재생성
        this.#month = value;

        this.makeCalendar();
      }
    }
  }

  get month(): number {
    return this.#month!;
  }

  availDateSet = new Set();

  private days = 0;

  #startDate?: string;

  #returnDate?: string;

  #initDate?: Date;

  @Input() defaultPeriod?: number;

  @Input()
  get startDate(): string {
    return this.#startDate!;
  }

  set startDate(str: string) {
    if (this.#startDate === str) {
      return;
    }

    this.#startDate = str;

    if (this.days > 0 && str) {
      const newDate = new Date(str);
      this.returnDate = new DatePipe('en').transform(
        newDate.setDate(newDate.getDate() + this.days),
        'yyyy-MM-dd',
      )!;
    }

    this.startDateChange.emit(this.#startDate);
  }

  @Input()
  get returnDate(): string {
    return this.#returnDate!;
  }

  set returnDate(str: string) {
    if (this.isNightTooLong(str)) {
      this.dialogService.alert('ALERT.Booking_Edit_Term_Error').subscribe();
      return;
    }

    if (this.#returnDate === str) {
      return;
    }
    this.#returnDate = str;
    this.returnDateChange.emit(this.#returnDate);
  }

  @Input()
  get initDate(): Date {
    return this.#initDate!;
  }

  set initDate(date: Date) {
    this.#initDate = date;

    if (!date) {
      return;
    }

    this.#year = this.initDate.getFullYear();
    this.#month = this.initDate.getMonth();
    this.makeCalendar();
  }

  device$: Observable<string>;

  @HostBinding('class.app')
  get isApp(): boolean {
    return Utils.isMobileSize();
  }

  /**
   * 추가 표시 일수
   */
  @Input()
  extraDates = 0;

  private prevYear?: number;

  private prevMonth?: number;

  constructor(
    private dialogService: DialogService,
    private uiService: UiService,
    // private package2023Service: Package2023Service,
    private package2024Service: Package2024Service,
    private homeScreenService: HomeScreenService,
    private shirosatoService: ShirosatoService,
  ) {
    this.device$ = this.uiService.device$;
  }

  ngOnInit(): void {
    this.today = new DatePipe('en').transform(new Date(), 'yyyy-MM-dd')!;
    this.todayDayjs = dayjs().endOf('day');
  }

  onYearSelectChange(year: number): void {
    if (this.year !== year) {
      this.#year = year;

      // 년, 월 전체 설정됐을떄만
      if (this.isValidNumber(this.year) && this.isValidNumber(this.month)) {
        this.monthChange.emit(new Date(this.year, this.month));
        this.makeCalendar();
      }
    }
  }

  onMonthSelectChange(month: number): void {
    if (this.month !== month) {
      this.#month = month;

      // 년, 월 전체 설정됐을떄만
      if (this.isValidNumber(this.year) && this.isValidNumber(this.month)) {
        this.monthChange.emit(new Date(this.year, this.month));
        this.makeCalendar();
      }
    }
  }

  onSearchButtonClick(): void {
    // 년, 월 전체 설정됐을떄만
    if (this.isValidNumber(this.year) && this.isValidNumber(this.month)) {
      this.monthChange.emit(new Date(this.year, this.month));
      this.makeCalendar();
    }
  }

  /**
   * null 또는 NaN이 아닌지 확인
   */
  isValidNumber(value: number): boolean {
    return value != null && !Number.isNaN(value);
  }

  isBeforeToday(d: string): boolean {
    return new Date(d) < new Date();
  }

  getText(date: string): {
    disabled: boolean;
    text?: string;
    [key: string]: any;
  } {
    if (this.homeScreenService.homeScreen.bookingCalendarType === 'NONE') {
      if (this.isBeforeToday(date)) {
        // 오늘보다 이전
        return { disabled: true, text: '' };
      }

      // 예약 달력 표시하지 않을때
      return { disabled: false, text: '' };
    }

    if (this.startDate === date && this.returnDate === date) {
      // 무박의 경우
      return { disabled: this.#disabled!, text: 'DayTour' };
    }

    const text = this.data?.get(date)?.infoMessage?.replace('(', '\n(');

    if (this.startDate === date) {
      // 선택한 출국일
      return {
        disabled: this.#disabled!,
        text,
        isMultiLine: text?.includes('\n'),
      };
    }

    if (this.returnDate === date) {
      // 선택한 귀국일
      return {
        disabled: this.#disabled!,
        text,
        isMultiLine: text?.includes('\n'),
      };
    }

    if (!this.data?.has(date)) {
      // 현재 보고있는 달력에 없는 날의 경우
      return { disabled: true, text: '' };
    }

    if (date === this.today) {
      // 오늘
      return { disabled: true, text: 'Today' };
    }

    if (this.isBeforeToday(date)) {
      // 오늘보다 이전
      return { disabled: true, text: 'Unavail' };
    }

    if (this.packagegoods?.bookingDaysUntilStart) {
      // 출발일까지 예약 가능한 날 설정 있으면

      // 예약 가능한 마지막 날
      const latestBookableDayjs = dayjs(date).subtract(
        this.packagegoods?.bookingDaysUntilStart,
        'day',
      );

      // 예약 가능한 날 이후면 선택 불가
      const notBookable = this.todayDayjs?.isAfter(latestBookableDayjs);

      if (notBookable) {
        return { disabled: true, text: '' };
      }
    }

    if (this.data.get(date)?.infoMessageOrig === '미오픈') {
      return {
        disabled: true,
        text,
      };
    }

    const isClose = this.data.get(date)?.infoMessageOrig?.includes('마감');

    let disabled: boolean;

    if (!this.startDate || (this.startDate && this.returnDate)) {
      // 출국일 선택중

      disabled = !this.isAvailStartDate(date, this.packagegoods);

      // 2024-06-11 메리트투어 남태희 상무님 요청으로 이바라키 시로사토 패키지 하드코딩
      // FIXME: 시로사토 하드코딩 제거
      if (this.shirosatoService.isShirosato(this.packagegoods)) {
        // EWRC 이바라키 시로사토 상품
        if (!disabled || this.shirosatoService.isTempAvailableStart(date)) {
          // 출발 가능한 날이면 가능 여부 다시 한번 검사
          if (!this.shirosatoService.isStartDtAvail(this.startPoint!, date)) {
            // 출발 가능일이 아님
            disabled = true;
          }

          if (
            this.shirosatoService.isTempAvailableStart(date) &&
            this.shirosatoService.isStartDtAvail(this.startPoint!, date)
          ) {
            disabled = false;
          }
        }
      }

      if (disabled) {
        return {
          disabled: true,
          text,
          isMultiLine: text?.includes('\n'),
        };
      }
    } else if (!this.returnDate) {
      // 귀국일 선택중
      if (date <= this.startDate) {
        return {
          disabled: true,
          text,
          isMultiLine: text?.includes('\n'),
        };
      }

      disabled = !this.package2024Service.isAvailReturnDate(
        this.startDate,
        date,
        this.packagegoods!,
      );

      // 2024-06-11 메리트투어 남태희 상무님 요청으로 이바라키 시로사토 패키지 하드코딩
      // FIXME: 시로사토 하드코딩 제거
      if (this.shirosatoService.isShirosato(this.packagegoods)) {
        // EWRC 이바라키 시로사토 상품
        if (
          !disabled ||
          this.shirosatoService.isTempAvailableReturn(this.startDate, date)
        ) {
          // 복귀 가능한 날이면 가능 여부 다시 한번 검사
          if (
            !this.shirosatoService.isReturnDtAvail(
              this.startPoint!,
              this.startDate,
              date,
            )
          ) {
            // 출발 가능일이 아님
            disabled = true;
          }

          if (
            this.shirosatoService.isTempAvailableReturn(this.startDate, date)
          ) {
            disabled = false;
          }
        }
      }
    }

    if (disabled!) {
      return {
        disabled,
        text,
        // close: isClose,
        isMultiLine: text?.includes('\n'),
      };
    }

    if (!isClose) {
      this.availDateSet.add(date);
    }

    return {
      disabled: this.#disabled!,
      text,
      close: isClose,
      isMultiLine: text?.includes('\n'),
    };
  }

  onDateColumnClick(date: string): void {
    if (this.getText(date).disabled === true) {
      return;
    }

    if (!this.startDate) {
      this.startDateClick(date);
      return;
    }

    if (!this.returnDate) {
      this.returnDateClick(date);
      return;
    }

    // 당일 여행은 날짜 바로 변경
    if (this.packagegoods?.tourTerm === 1) {
      this.startDateClick(date);
      return;
    }

    this.resetSelected();
  }

  private async startDateClick(date: string): Promise<void> {
    if (
      this.data?.get(date)?.infoMessageOrig === '예약마감' ||
      this.data?.get(date)?.infoMessageOrig === '마감'
    ) {
      await firstValueFrom(
        this.dialogService.alert(
          '예약이 마감된 날입니다. 예약가능한 날짜를 선택해주세요.',
        ),
      );

      this.resetSelected();

      return;
    }

    this.startDate = date;
    const availDateList = Array.from(this.availDateSet);
    const lastDate = availDateList[availDateList.length - 1];

    if (this.packagegoods?.tourTerm === 1) {
      this.returnDate = date;
      // this.setFlightViaDialog();
    }

    if (
      this.homeScreenService.homeScreen
        .bookingCalendarAutoSelectReturnDateFlg &&
      this.packagegoods?.packagegoodsScheduleType === 'DAY'
    ) {
      await this.returnDateClick(
        dayjs(this.startDate)
          .add(this.packagegoods.tourTerm! - 1, 'day')
          .format('YYYY-MM-DD'),
      );
    }

    if (this.startDate === lastDate) {
      this.lastAvailDateSelect.emit();
    }
  }

  private async returnDateClick(date: string): Promise<void> {
    const diff = dayjs(date).diff(dayjs(this.startDate), 'day');

    if (diff <= 0) {
      return;
    }

    const dateInfoList = this.package2024Service.getDateInfoList(
      this.data!,
      this.startDate,
      date,
    );

    const messageList = dateInfoList.map(
      ({ infoMessageOrig }) => infoMessageOrig,
    );

    if (
      messageList.indexOf('예약마감') >= 0 ||
      messageList.indexOf('마감') >= 0
    ) {
      await firstValueFrom(
        this.dialogService.alert('ALERT.Booking_Excess_Tour_Number_In_Term'),
      );

      this.resetSelected();
      return;
    }

    this.returnDate = date;
    // this.setFlightViaDialog();

    this.dateSelected.emit([this.#startDate!, this.#returnDate!]);
  }

  isIn(d: string): boolean {
    if (!this.startDate || !this.returnDate) {
      return false;
    }

    return (
      new Date(this.startDate) < new Date(d) &&
      new Date(d) < new Date(this.returnDate)
    );
  }

  hasTourTerm(d: string): boolean {
    if (this.days < 1 || !this.returnDate) {
      return false;
    }

    if (d === this.startDate || d === this.returnDate || this.isIn(d)) {
      return false;
    }

    return true;
  }

  /**
   * 주말(토,일) 여부
   */
  isWeekend(date: string): boolean {
    const day = dayjs(date).day();
    return day === 0 || day === 6;
  }

  /**
   * 휴일 여부
   *
   * TODO: 패키지상품에 설정된 휴일 설정을 체크
   */
  isHoliday(date: string): boolean {
    if (this.homeScreenService.homeScreen.brandId === 2) {
      return jpHolidayList.indexOf(date) > -1;
    }

    return false;
  }

  isExtraDate(date: string): boolean {
    const djs = dayjs(date);
    return djs.year() !== this.prevYear || djs.month() !== this.prevMonth;
  }

  /**
   * 캘린더 데이터 생성
   */
  private makeCalendar(): void {
    // 숫자 올바르지 않으면 생성하지 않음
    if (!this.isValidNumber(this.year) || !this.isValidNumber(this.month)) {
      return;
    }

    this.prevYear = this.year;
    this.prevMonth = this.month;

    this.availDateSet = new Set();
    const firstDayOfWeek = new Date(this.year, this.month).getDay();
    const calendar: any[][] = [];
    let date = 1;

    const daysInMonth =
      this.daysInMonth(this.year, this.month) + this.extraDates;

    for (let i = 0; i < 8; i += 1) {
      calendar[i] = [];
      for (let j = 0; j < 7; j += 1) {
        if (i === 0 && j < firstDayOfWeek) {
          calendar[i][j] = '';
        } else if (date > daysInMonth) {
          if (i < 5) {
            calendar[i + 1] = [];
            calendar[i + 1][0] = '';
          } else {
            calendar[i][j] = '';
          }

          break;
        } else {
          calendar[i][j] = new DatePipe('en').transform(
            new Date(this.year, this.month, date),
            'yyyy-MM-dd',
          );
          date += 1;
        }
      }

      if (date >= daysInMonth) {
        break;
      }
    }

    this.calendar = calendar;
  }

  // 해당 연, 월에 몇일까지 있는지
  private daysInMonth(y: number, m: number): number {
    return 32 - new Date(y, m, 32).getDate();
  }

  /**
   * 변경하는 여행기간이 변경 전보다 긴지 확인
   */
  private isNightTooLong(returnDate: string): boolean {
    if (!this.dataBeforeChange) {
      return false;
    }

    if (!returnDate) {
      return false;
    }

    const maxNight = Math.abs(
      new Date(this.dataBeforeChange.startDate).valueOf() -
        new Date(this.dataBeforeChange.returnDate).valueOf(),
    );

    return (
      Math.abs(
        new Date(this.startDate).valueOf() - new Date(returnDate).valueOf(),
      ) > maxNight
    );
  }

  /**
   * @deprecated
   */
  private setFlightViaDialog(): void {
    const { departingFlightsJson, returnFlightsJson } = this.packagegoods!;

    const filteredDepartingFlights = JSON.parse(
      departingFlightsJson || '[]',
    ).filter(({ startPoint, destinationPoint }: { [k: string]: string }) => {
      return (
        startPoint.indexOf(this.startPoint!) >= 0 &&
        destinationPoint.indexOf(this.destinationPoint!) >= 0
      );
    });

    const filteredReturnFlights = JSON.parse(returnFlightsJson || '[]').filter(
      ({ startPoint, destinationPoint }: { [k: string]: string }) => {
        return (
          startPoint.indexOf(this.destinationPoint!) >= 0 &&
          destinationPoint.indexOf(this.startPoint!) >= 0
        );
      },
    );
    this.package2024Service.openFlightSelectModal(
      filteredDepartingFlights,
      (df) => {
        if (!df) {
          return;
        }

        this.package2024Service.departingFlight = df;

        this.package2024Service.openFlightSelectModal(
          filteredReturnFlights,
          (rf) => {
            if (!rf) {
              return;
            }

            this.package2024Service.returnFlight = rf;
          },
          'ALERT.Input_Return_Flight',
          '귀국',
        );
      },
      'ALERT.Input_Start_Flight',
      '출국',
    );

    this.package2024Service.packagegoods = this.packagegoods;
  }

  private resetSelected(): void {
    this.startDate = null!;
    this.returnDate = null!;
    this.package2024Service.departingFlight = null;
    this.package2024Service.returnFlight = null;
    this.package2024Service.packagegoods = null;
    this.dateResetted.emit();
  }

  /**
   * 예약 가능일자 여부
   */
  private isAvailStartDate(
    startDate: string,
    packagegoods?: IPackagegoods,
  ): boolean {
    // 정보 없으면 선택 불가
    if (!startDate || !packagegoods) {
      return false;
    }

    const { startDtFrom, startDtTo, onlineSettingJson } = packagegoods;

    if (startDtFrom) {
      const isBeforeStartDt = dayjs(startDate).isBefore(startDtFrom, 'day');

      if (isBeforeStartDt) {
        return false;
      }
    }

    if (startDtTo) {
      const isAfterStartDt = dayjs(startDate).isAfter(startDtTo, 'day');

      if (isAfterStartDt) {
        return false;
      }
    }

    // 온라인 설정 없으면 전부 가능
    if (onlineSettingJson) {
      const onlineSetting: OnlineSetting = Utils.getParsedJson(
        packagegoods.onlineSettingJson!,
      );

      // 모든 요일의 설정이 없는지 확인
      let concatedTourTermList: number[] = [];

      Object.values(onlineSetting).forEach((tourTermList: number[]) => {
        // 요일 추가
        concatedTourTermList = concatedTourTermList.concat(tourTermList);
      });

      // 하나도 없으면 모든 날 가능
      if (!concatedTourTermList?.length) {
        return true;
      }

      const availTourTermList = this.getAvailTourTermList(
        startDate,
        packagegoods.onlineSettingJson!,
      );

      if (!availTourTermList?.length) {
        return false;
      }
    }

    return true;
  }

  /**
   * 현재 출발일에서 선택 가능한 박수 목록 획득
   */
  private getAvailTourTermList(
    startDate: string,
    onlineSettingJson: string,
  ): number[] {
    // 패키지 온라인 설정 파싱
    const onlineSetting: OnlineSetting = Utils.getParsedJson(
      onlineSettingJson,
      {},
    );
    // 출발 가능 요일 비교
    const dayOfWeekIndex = dayjs(startDate).get('day');
    const dayOfWeek = INDEX_TO_DOW[dayOfWeekIndex];
    const [availDow] = Object.keys(onlineSetting).filter(
      (key) => key === dayOfWeek,
    ) as [keyof OnlineSetting];

    // 일치하는 요일 없으면 빈 배열 반환하며 종료
    if (!availDow) {
      return [];
    }

    return onlineSetting[availDow];
  }
}
